@import '../../assets/scss/variables.scss';

.delivery {
    &__general {
        display: grid;
        grid-template-columns: repeat(2, 50%);
    }

    &__map {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        box-shadow: 0px 0px 8px $theme-color;
    }

    &__text {
        padding: 75px 0;
        padding-right: 0 !important;
        box-sizing: border-box;
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }

    &__item {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 15px;

        &::before {
            content: '';
            display: block;
            width: 30px;
            height: 2px;
            background: $general-color;
            position: relative;
            top: calc(((25px - 2px) / 2) - 1px);
        }
    }

    &__get-in-touch {
        display: grid;
        grid-template-areas: '. button .';
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 75px;
        width: 100%;
        text-align: center;
    }

    &__button {
        width: 100%;
        grid-area: button;
    }

    // Media queries //

    @media screen and (max-width: 992px) {
        &__title {
            margin-bottom: 60px;
        }

        &__general {
            grid-template-columns: 100%;
            grid-template-areas: 'text' 'map';
            row-gap: 60px;
        }

        &__map {
            height: 400px;
            grid-area: map;
        }

        &__text {
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0 !important;
            padding-left: 0 !important;
        }

        &__get-in-touch {
            margin-top: 60px;
            grid-template-columns: 1fr 2fr 1fr;
        }
    }

    @media screen and (max-width: 768px) {
        &__get-in-touch {
            grid-template-columns: 1fr 3fr 1fr;
        }
    }

    @media screen and (max-width: 576px) {
        &__map {
            height: 370px;
        }

        &__get-in-touch {
            grid-template-columns: 1fr 6fr 1fr;
        }
    }

    @media screen and (max-width: 440px) {
        &__map {
            height: 340px;
        }

        &__get-in-touch {
            grid-template-columns: 0 100% 0;
        }
    }
}