@import '../../assets/scss/variables.scss';

.our-puppies {
    width: 100%;
    background: $theme-color;

    &__container {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    &__image-wp {
        height: 100%;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            height: 390px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            background: $theme-color;
            opacity: 0.3;
            z-index: 2;
            border-radius: 50%;
        }
    }

    &__image {
        width: 100%;
        height: 370px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0 0 0 17.5px $theme-color;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
    }

    &__text {
        padding: 70px 0;
        padding-left: 0 !important;
    }

    &__title {
        position: relative;

        &::before {
            content: '';
        }
    }

    // Media queries //

    @media screen and (max-width: 1230px) {
        &__container {
            grid-template-columns: 7fr 5fr;
        }
    }

    @media screen and (max-width: 992px) {
        &__container {
            grid-template-columns: 100%;
            grid-template-areas: "image" "text";
        }

        &__image-wp {
            grid-area: image;
            top: -15px;
            margin-bottom: -15px;

            &::after {
                top: 0;
                height: calc(100% + 15px);
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        &__image {
            position: static;
            transform: none;
            margin: auto;
            display: block;
        }

        &__text {
            grid-area: text;
            padding-top: 60px;
            padding-right: 0 !important;
        }
    }

    @media screen and (max-width: 576px) {
        &__image {
            width: 100%;
        }

        &__image-wp {
            margin-bottom: -15px;
            position: relative;
            top: -15px;
        }
    }
}