@import '../../assets/scss/variables.scss';

.about-us {
    &__container {
        display: grid;
        grid-template-areas: "image title" "image subtitle" "image description";
        grid-template-columns: 1fr 2fr;
        position: relative;
    }

    &__paws {
        width: 100px;
        height: auto;
        position: absolute;
        bottom: calc(100% - 30px);
        right: -50px;
        z-index: 1;
    }

    &__image-wp {
        z-index: 2;
        position: relative;
        height: 100%;
        width: 100%;
        grid-area: image;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: inherit;
            border-radius: 190px 8px 8px 8px;
            background: $theme-color;
            opacity: 0.3;
            z-index: 2;
        }
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-height: 100%;
        border-radius: 190px 8px 8px 8px;
        box-shadow: -17.5px -17.5px $theme-color;
        z-index: 1;
    }

    &__text {
        position: relative;
        z-index: 2;
        padding-right: 0 !important;
    }

    &__title {
        grid-area: title;
    }

    &__subtitle {
        display: flex;
        column-gap: 45px;
        align-items: center;
        color: $theme-color;
        margin-bottom: 45px;
        grid-area: subtitle;
        grid-template-areas: "line .";
        grid-template-columns: auto 1fr;

        &::before {
            content: '';
            transform: initial;
            position: relative;
            top: 0;
            left: 0;
        }
    }

    &__description {
        grid-area: description;
    }

    // Media queries //

    @media screen and (max-width: 1350px) {
        &__paws {
            width: 90px;
            bottom: calc(100% - 5px);
            right: -10px;
        }
    }

    @media screen and (max-width: 1230px) {
        &__container {
            grid-template-columns: 5fr 7fr;
        }
    }

    @media screen and (max-width: 992px) {
        &__container {
            grid-template-columns: 100%;
            grid-auto-flow: row;
            grid-template-areas: "title" "subtitle" "image" "description";
            grid-auto-rows: auto;
        }

        &__image-wp {
            height: 400px;
            margin: 15px 0 45px 0;
            display: block;
        }

        &__text {
            padding-left: 0 !important;
        }

        &__paws {
            width: 80px;
            bottom: calc(100% - 30px);
            right: 20px;
        }

        &__title {
            padding-right: 125px !important;
            box-sizing: border-box;
        }
    }

    @media screen and (max-width: 576px) {
        &__paws {
            width: 80px;
            bottom: calc(100% - 40px);
        }

        &__title {
            padding-right: 125px !important;
            box-sizing: border-box;
        }

        &__image {
            box-shadow: -10px -10px #eacdc7;
        }
    }

    @media screen and (max-width: 440px) {
        &__paws {
            width: 70px;
            bottom: calc(100% - 30px);
        }

        &__title {
            padding-right: 100px !important;
            box-sizing: border-box;
        }
    }
}