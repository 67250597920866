@import '../../assets/scss/variables.scss';

.pop-up {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(255, 255, 255, 0.15);
    z-index: 99;
    overflow-y: auto;
    display: none;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);

    &--show {
        display: block;
    }

    &__scroll {
        height: auto;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 75px;
        padding-bottom: 75px;
        box-sizing: border-box;
        z-index: 2;
    }

    &__inner {
        width: 100%;
        max-width: calc(768px - 30px * 2);
        box-sizing: border-box;
        height: auto;
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        background: #fff;
        padding: 75px;
        box-shadow: 0 0 5px 2.5px $theme-color;
        border-radius: 8px;
        position: relative;
    }

    &__close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        position: absolute;
        top: calc(75px / 2);
        right: calc(75px / 2);
    }

    &__close-icon {
        height: 32.5px;
        width: auto;
        cursor: pointer;
    }

    &__title {
        margin-top: 32.5px;
        text-align: center;
        margin-bottom: 15px;
    }

    &__description {
        text-align: center;
        color: $theme-text-color;
    }

    &__link {
        color: inherit;
        text-decoration: underline;
        white-space: nowrap;
    }

    &__form {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: auto;
        grid-auto-rows: auto;
        grid-row-gap: 25px;
        margin-top: 30px;
    }

    &__input {
        height: auto;
    }

    &__textarea {
        height: 160px;
        resize: none;
    }

    &__input,
    &__textarea {
        width: 100%;
        padding: 15px 20px;
        box-sizing: border-box;
        border: 1px solid $theme-color;
        border-radius: 8px;
        color: $theme-text-color;
        font-weight: 700;

        &::placeholder {
            color: $theme-text-color;
            opacity: 0.7;
        }
    }

    &__button {
        justify-self: center;
    }

    @media screen and (max-width: 1230px) {
        &__close {
            top: calc(60px / 2);
            right: calc(60px / 2);
        }

        &__close-icon {
            height: 32.5px;
        }

        &__title {
            margin-top: 32.5px;
        }

        &__inner {
            padding: 60px;
        }

        &__scroll {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    @media screen and (max-width: 768px) {
        &__inner {
            padding: 60px 30px;
        }
    }

    @media screen and (max-width: 576px) {
        &__close {
            top: calc(45px / 2);
            right: calc(45px / 2);
        }

        &__close-icon {
            height: 30px;
        }

        &__inner {
            padding: 45px 15px;
        }

        &__title {
            margin-top: calc(30px);
        }

        &__scroll {
            padding-top: 45px;
            padding-bottom: 45px;
        }
    }

    @media screen and (max-width: 440px) {
        &__description br {
            display: none;
        }
    }
}