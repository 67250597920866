@import '../../assets/scss/variables.scss';

.hide-menu {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    background: rgba(255, 255, 255, 0.15);
    z-index: 98;
    display: none;
    backdrop-filter: blur(5px);
    overflow-y: auto;
    opacity: 0;
    transition: opacity 0.5s;
    overflow: auto;
    grid-template-columns: 1fr 340px;

    &--show {
        opacity: 1;
        transition-delay: 0.4s;
    }

    &--show &__inner {
        right: 0;
        transition-delay: 0.3s;
    }

    &__wrapper {
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: grid;
        grid-template-columns: 1fr 340px;
        overflow: hidden;
    }

    &__background {
        width: 100%;
        height: 100%;
    }

    &__inner {
        width: 100%;
        right: -380px;
        position: relative;
        transition: right 0.5s;
        height: auto;
        min-height: 100vh;
        margin-left: auto;
        display: grid;
        grid-auto-flow: row;
        background: #fff;
        box-shadow: 0px 0px 10px 1px $theme-color;
        grid-template-rows: minmax(1fr, 90px) auto 1fr;
        z-index: 2;
    }

    &__close {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 90px;
        margin-bottom: calc(60px - (90px - 26.5px) / 2);
    }

    &__close-icon {
        height: 26.5px;
        width: auto;
        cursor: pointer;
    }

    &__nav {
        flex-direction: column;
        align-items: center;
        align-self: center;
        justify-self: center;
        justify-content: space-between;
    }

    &__phones {
        justify-self: center;
        align-self: flex-end;
        margin-bottom: 90px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        align-content: center;
    }
}

.header {
    height: 90px;

    &__container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__hide-menu {
        display: none; // flex
        width: 38px;
        height: 26.5px;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        align-items: flex-end;
        overflow: hidden;
    }

    &__hide-menu-line {
        width: 100%;
        height: 3.5px;
        border-radius: 3px;
        background: $general-color;
        position: relative;
        right: 0;
        transition: all 0.5s;
        transition-delay: 0s;

        &:nth-child(2) {
            width: 75%;
            transition-delay: 0.1s;
        }

        &:nth-child(3) {
            width: 50%;
            transition-delay: 0.2s;
        }
    }

    &__phones {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        align-content: flex-end;
        align-items: center;
        position: relative;
        justify-content: flex-end;
    }

    &__phone {
        align-self: flex-end;
    }
}

@media screen and (max-width: 1230px) {
    .header {

        &__nav,
        &__phones {
            display: none;
        }

        &__hide-menu {
            display: flex;
        }
    }

    body.HM--show {
        overflow: hidden;

        .hide-menu {
            display: block;
        }

        .header__hide-menu-line {
            right: 100%;
        }
    }
}

@media screen and (max-width: 576px) {
    .header {
        &__hide-menu {
            width: 35px;
        }

        &__hide-menu-line {
            height: 3px;
        }
    }
}

@media screen and (max-width: 400px) {
    .hide-menu__wrapper {
        grid-template-columns: 100%;
    }

    .hide-menu__background {
        display: none;
    }
}