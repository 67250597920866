@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;700&display=swap');
@import './variables.scss';

* {
    padding: 0;
    margin: 0;
    color: $general-color;
    text-decoration: none;
    outline: none;
    list-style: none;
    background: none;
    border: none;
    font-family: 'Comfortaa', cursive;
}

body {
    background: #fff;

    &.overflow--hidden {
        overflow: hidden;
    }
}

b,
strong {
    font-weight: 700;
}

section {
    margin-bottom: 135px;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 700;
}

h6 {
    font-weight: 300;
}

h6,
h3 {

    font-size: 24px;
}

h1 {
    font-size: 70px;
    line-height: 125.5%;
}

h2 {
    font-size: 44px;
    line-height: 49px;
    margin-bottom: 30px;
}

p {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
}

a {
    cursor: pointer;
}

.button {
    cursor: pointer;
    padding: 17.5px 30px;
    border-radius: 8px;
    position: relative;
    transition: all 0.15s;

    &__text {
        position: relative;
        z-index: 1;
        color: #fff;
        font-weight: 700;
        font-size: 17px;
        transition: all 0.15s;
        right: 0;
        top: 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: inherit;
        border: 1px solid $general-color;
        transition: all 0.15s;
    }

    &::after {
        content: '';
        height: 100%;
        position: absolute;
        border-radius: inherit;
        width: 100%;
        background: $general-color;
        transition: all 0.15s;
        right: 0;
        top: 0;
    }

    &:hover::after,
    &:hover &__text {
        top: -4px;
        right: -4px;
    }
}

.container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: auto;

    &-fluid {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }
}

.logo,
.logo-wp {
    height: auto;
    width: 280px;
}

.phone {
    width: auto;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    position: relative;
    transition: all 0.25s;
    box-sizing: border-box;

    &__icon {
        width: 21px;
        height: 21px;

        * {
            transition: all 0.25s;
        }
    }

    &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 0;
        left: 0;
        bottom: 0;
        background: #AE8D86;
        transition: all 0.25s;
    }

    &:hover {
        color: #AE8D86;

        &::after {
            width: 100%;
        }

        .phone__icon * {
            fill: #AE8D86 !important;
        }
    }
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    row-gap: 30px;
    flex-direction: row;

    &__link {
        font-weight: 300;
        font-size: 15.5px;
        position: relative;
        transition: all 0.25s;
        padding: 8.5px 0;
        box-sizing: border-box;

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 0;
            left: 0;
            bottom: 0;
            background: #AE8D86;
            transition: all 0.25s;
        }

        &:hover {
            color: #AE8D86;

            &::after {
                width: 100%;
            }
        }
    }
}

.text-wp{
    &--padding {
        box-sizing: border-box;
        padding-left: 75px !important;
        padding-right: 75px !important;
    }
}

.title-wp--line {
    &::before,
    &::after {
        width: 100px;
        height: 2px;
        background: $general-color;
        top: calc(49px / 2 - 2px);
        left: -45px;
        transform: translate(-100px, 0);
        display: block;
        position: absolute;
    }
}

@media screen and (max-width: 1500px) {
    .title-wp--line {
        display: grid !important;
        grid-template-columns: 1fr auto;
        column-gap: 45px;
        grid-template-areas: ". line";

        &::before,
        &::after {
            left: 0 !important;
            right: 0 !important;
            transform: initial;
            grid-area: line;
            position: relative;
        }
    }
}

@media screen and (max-width: 1230px) {
    .container {
        max-width: calc(992px - 30px);

        &-fluid {
            padding: 0 30px;
        }
    }

    .text-wp--padding {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
}

@media screen and (max-width: 992px) {
    section {
        margin-bottom: 90px;
    }

    .container {
        max-width: calc(100% - 30px);
    }
}

@media screen and (max-width: 768px) {
    .logo,
    .logo-wp {
        width: 250px;
    }
}

@media screen and (max-width: 576px) {
    section {
        margin-bottom: 70px;
    }

    h1 {
        font-size: 65px;
    }

    h2 {
        font-size: 40px;
        line-height: 46px;
    }

    h6,
    h3 {
        font-size: 22px;
    }

    p {
        font-size: 15px;
    }

    .container {
        max-width: 100%;

        &-fluid {
            padding: 0 15px;
        }
    }

    .title-wp--line {
        &::before,
        &::after {
            top: calc(46px / 2 - 2px);
        }
    }
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 58px;
    }

    h2 {
        font-size: 38px;
        line-height: 44px;
    }

    .title-wp--line {
        &::before,
        &::after {
            top: calc(44px / 2 - 2px);
        }
    }
}

@media screen and (max-width: 440px) {
    h1 {
        font-size: 53px;
    }

    h2 {
        font-size: 34px;
        line-height: 42px;
    }

    .logo,
    .logo-wp {
        width: 240px;
    }

    .title-wp--line {
        column-gap: 25px;

        &::before,
        &::after {
            width: 75px;
            top: calc(42px / 2 - 2px);
        }
    }
}

@media screen and (max-width: 400px) {
    h1 {
        font-size: 48px;
    }

    h6,
    h3 {

        font-size: 20px;
    }

    h2 {
        font-size: 30px;
        line-height: 38px;
    }

    .title-wp--line {
        &::before,
        &::after {
            top: calc(38px / 2 - 2px);
        }
    }
}

@media screen and (max-width: 360px) {
    h1 {
        font-size: 43px;
    }

    .logo,
    .logo-wp {
        width: 220px;
    }
}

@media screen and (max-width: 330px) {
    h1 {
        font-size: 42px;
    }

    .title-wp--line {
        &::before,
        &::after {
            width: 60px;
            top: calc(42px / 2 - 2px);
        }
    }
}

@media screen and (max-width: 319px) {
    h1 {
        font-size: 36.5px;
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .logo,
    .logo-wp {
        width: 220px;
    }

    .button {
        padding: 17.5px 0;
        width: 100%;
    }

    .title-wp--line {
        &::before,
        &::after {
            top: calc(36px / 2 - 2px);
        }
    }
}

@media screen and (max-width: 319px) {
    h1 {
        font-size: 36.5px;
    }

    .logo,
    .logo-wp {
        width: 220px;
    }

    .title-wp--line {
        column-gap: 15px;

        &::before,
        &::after {
            width: 45px;
            top: calc(36px / 2 - 2px);
        }
    }
}

@media screen and (max-width: 290px) {
    .logo,
    .logo-wp {
        width: 200px;
    }
}