@import '../../assets/scss/variables.scss';

.benefits {
    &__title {
        position: relative;
        margin-bottom: 75px;

        &::before {
            content: '';
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        gap: 30px;
    }

    &__benefit {
        box-sizing: border-box;
        padding: 50px;
        background: #fff;
        box-shadow: 0px 0px 8px $theme-color;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        top: 0;
        transition: all 0.35s;
    }

    &__benefit:hover {
        background: $theme-text-color;
        top: -10px;
    }

    &__benefit * {
        transition: all 0.35s;
    }

    &__benefit svg * {
        fill: #fff !important;
        stroke: #fff !important;
    }

    &__benefit:hover * {
        color: #fff !important;
    }


    &__benefit:hover &__benefit-icon-wp {
        background: #fff;
    }

    &__benefit:hover &__benefit-icon * {
        fill: $theme-text-color !important;
        stroke: $theme-text-color !important;
    }

    &__benefit-title-wp {
        display: grid;
        grid-template-columns: auto 1fr;
        margin-bottom: 25px;
        column-gap: 20px;
        align-items: center;
    }

    &__benefit-icon-wp {
        width: 50px;
        height: 50px;
        background: $theme-text-color;
        border-radius: 50%;
        display: grid;
        align-items: center;
        justify-content: center;

    }

    &__benefit-icon {
        width: 24px;
        height: 24px;
        fill: #fff;
    }

    &__benefit-title {
        color: $theme-text-color;
    }

    // Media queries //

    @media screen and (max-width: 768px) {
        &__list {
            grid-template-columns: 100%;
        }
    }
    
    @media screen and (max-width: 440px) {
        &__benefit {
            padding: 50px 30px;
        }
    }
}