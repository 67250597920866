@import '../../assets/scss/variables.scss';

.articles {
    &__title {
        position: relative;
        margin-bottom: 75px;

        &::before {
            content: '';
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        gap: 30px;
    }

    &__article {
        box-sizing: border-box;
        padding: 50px;
        background: #fff;
        box-shadow: 0px 0px 8px $theme-color;
        border-radius: 8px;
        position: relative;
        top: 0;
        align-self: flex-start;
    }

    &__article-title {
        margin-bottom: 25px;
    }

    &__article-button {
        margin-top: 32px;
        display: block;
    }

    [data-show="false"] .articles__article-description {
        display: none;
    }

    &__article-description {
        transition: opacity 0.25s;
        width: 100%;
        overflow: hidden;
        margin-top: 0;
    }

    // Media queries //

    @media screen and (max-width: 768px) {
        &__list {
            grid-template-columns: 100%;
        }
    }
    
    @media screen and (max-width: 440px) {
        &__article {
            padding: 50px 30px;
        }
    }
}